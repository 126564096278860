import { render, staticRenderFns } from "./SignUpIntro.vue?vue&type=template&id=68c70ec1&scoped=true"
import script from "./SignUpIntro.vue?vue&type=script&lang=ts"
export * from "./SignUpIntro.vue?vue&type=script&lang=ts"
import style0 from "./SignUpIntro.vue?vue&type=style&index=0&id=68c70ec1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68c70ec1",
  null
  
)

export default component.exports