
import { Component, Vue } from "vue-property-decorator";
// import Navbar from "@/components/Utility/Navbar.vue";
// import Footer from "@/components/Utility/Footer.vue";
import SignUpIntro from "@/components/SignUp/SignUpIntro.vue";
import SignUpForm from "@/components/SignUp/SignUpForm.vue";
import SignUpFinish from "@/components/SignUp/SignUpFinish.vue";
// eslint-disable-next-line
// @ts-ignore
import VueFacebookPixel from '@blaaat/vue-facebook-pixel'
Vue.use(VueFacebookPixel);

declare type IRecruitment = {
  activityEndTime:string;
  activityStartTime:string;
  createBy:string;
  createDate:string;
  enrollEndDate:string;
  enrollStartDate:string;
  id:number;
  location:string;
  name:string;
  numberOfParticipants:number;
  quota:number;
  status:number;
  type:number;
  updateBy:string;
  updateDate:string;  
};

@Component({
  components: {
    // Navbar,
    // Footer,
    SignUpIntro,
    SignUpForm,
    SignUpFinish,
  },
})
export default class SignUp extends Vue {
  //data
  introVisible = true;
  formVisible = false;
  finishVisible = false;
  isLoading = false;
  dataFromChild = {};
  viewWidth = 0; //null

  //hook
  mounted(): void {
    this.viewWidth = window.innerWidth;
    window.onresize = () => {
      this.viewWidth = window.innerWidth;
    };
  }

  created():void {
    // eslint-disable-next-line
    // @ts-ignore
    this.$analytics.fbq.event('ViewContent');
  }

  //methods
  next(): void {
    this.introVisible = false;
    this.formVisible = true;
    this.finishVisible = false;
    if (this.viewWidth > 400) {
      document.documentElement.scrollTop = 300;
    } else {
      document.documentElement.scrollTop = 0;
    }
    // eslint-disable-next-line
    // @ts-ignore
    this.$analytics.fbq.event('Contact');
  }
  send(data:IRecruitment): void {
    this.isLoading = true;
    this.dataFromChild = data;

    this.introVisible = false;
    this.formVisible = false;
    this.finishVisible = true;

    if (this.viewWidth > 400) {
      document.documentElement.scrollTop = 300;
    } else {
      document.documentElement.scrollTop = 0;
    }

  }
  back(): void {
    this.introVisible = true;
    this.formVisible = false;
    this.finishVisible = false;
    document.documentElement.scrollTop = 0;
  }
}
