
import { Component, Vue,Watch } from "vue-property-decorator";
import Loading from "@/components/Utility/Loading.vue";
import tigermaster from "fdtigermaster-client-sdk";
// eslint-disable-next-line
// @ts-ignore
import VueFacebookPixel from '@blaaat/vue-facebook-pixel'
Vue.use(VueFacebookPixel);

declare type IRecruitment = {
  activityEndTime:string;
  activityStartTime:string;
  createBy:string;
  createDate:string;
  enrollEndDate:string;
  enrollStartDate:string;
  id:number;
  location:string;
  name:string;
  numberOfParticipants:number;
  quota:number;
  status:number;
  type:number;
  updateBy:string;
  updateDate:string;   
};

@Component({
  components: {
    Loading,
  },
})
export default class SignUpForm extends Vue {
  //data
  itemobj = { id: "" };
  sessionInfo: IRecruitment[] = [];
  sessionId = "";
  name = "";
  phone = "";
  sex = "";
  email = "";
  infoSource = "";
  infoInput = "";
  isWarningShow = false;
  checkState: (boolean|null)[] = [true, true, true, true, true, true];
  isLoading = false;
  isShowMaster = false;
  isShowOther = false;
  referrerPhone = "";
  
  //hook
  created(): void {
    this.getSessionInfo();
  }

  //methods
  async getSessionInfo(): Promise<void> {
    const res = await tigermaster.database
      .query("activity_session")
      .where("activity_session.type", "=", 0)
      .where("activity_session.status", "=", 1)
      .orderBy("activity_session.activity_start_time", "asc")
      .get();
      
    this.sessionInfo = res.data;
  }
  async signUpRecruitment():Promise<void>{     
    const infoSource = this.returnInfoSource(this.infoSource,this.infoInput,this.referrerPhone);
    const needToCheck = [this.name, this.validation, this.sex, this.email, this.itemobj.id, infoSource];    
    for(let i = 0; i < needToCheck.length; i++){
      this.checkState[i] = Boolean(needToCheck[i]);
     }
    if(this.checkState.includes(false)){
      this.isWarningShow = true;
      document.documentElement.scrollTop = 0;     
      return; 
    }    

    try{
      this.isLoading = true;
      await tigermaster.activityService.createRecruitmentParticipant({
        infoSource:infoSource,
        sessionId:Number(this.itemobj.id),
        email:this.email,
        name:this.name,
        phone:this.phone,
        sex:this.sex,
        referrerPhone:this.validationRecommend ? this.referrerPhone : ''
      });

      // eslint-disable-next-line
      // @ts-ignore
      this.$analytics.fbq.event('CompleteRegistration');
    }finally{
      this.$emit('signUpRecruitmentEmit', this.itemobj);  
      this.isLoading = false;
    }
  }
  sliceStr(str:string, startIndex:number, length:number):string{
    return str.substr(startIndex, length);
  }  
  getDayOfWeek(dateStr: string): string {
    const day = new Date(dateStr).getDay();
    const dayArray = ['(日)', '(一)', '(二)', '(三)', '(四)', '(五)', '(六)'];
    
    return dayArray[day];
  }   
  returnInfoSource(infoSource: string, infoInput: string,referrerPhone:string): string{
      if (infoSource === "師傅推薦"){
         return this.validationRecommend ? `${infoSource}:${referrerPhone}` : '';
      } else if ( infoSource === "其他"){
        return `${infoSource}:${infoInput}`;
      } else {
        return infoSource;
      }
  }

  
 //computed
  get validation(): boolean | null {
    let isNumber = /^(09)[0-9]{8}$/;
    if (this.phone == "") {
      return null;
    } else {
      return isNumber.test(this.phone);
    }
  }

 get validationRecommend(): boolean | null {
    let isNumber = /^(09)[0-9]{8}$/;
    if (this.referrerPhone == "") {
      return null;
    } else {
      return isNumber.test(this.referrerPhone);
    }
  }
  //watch
  @Watch('infoSource')
  watchInfoSource():void{
      this.infoInput = '';
      this.referrerPhone='';
     if(this.infoSource === '師傅推薦'){
      this.isShowMaster = true
      this.isShowOther = false      
     }else if(this.infoSource==='其他'){
      this.isShowMaster = false
      this.isShowOther = true      
     }else if(this.infoSource !=='師傅推薦' && this.infoSource!=='其他'){
      this.isShowMaster = false
      this.isShowOther = false      
     }
     
     
  }  
  
}


 
