
import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class SignUpFinish extends Vue {
  @Prop() public back!: any;
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop() public dataFromChild!: any;

  //data

  //methods
  sliceStr(str:string, startIndex:number, length:number):string{
    return str.substr(startIndex, length);
  }
  getDayOfWeek(dateStr: string): string {
    const day = new Date(dateStr).getDay();
    const dayArray = ['(日)', '(一)', '(二)', '(三)', '(四)', '(五)', '(六)'];
    
    return dayArray[day];
  }
}
